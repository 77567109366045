<template>
  <el-tabs v-model="activeName" type="card">
    <el-tab-pane label="加收申请单" name="second">
      <div class="headerbut">
        <el-button class="seniorbut" @click="SubmitBut">提交</el-button>
        <el-button class="ordinarybut" @click="cancelbut">取消</el-button>
      </div>
      <div class="box2">
        <div class="queryItem1">
          <div class="queryLabel FontStyle">代理加收编号:</div>
          <div class="right_inpu">
            <el-input
              :disabled="Disable"
              v-model="arraydata.otherFinanceNumber"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle redtitle">渠道代理:</div>
          <div class="right_inpu">
            <el-select v-model="arraydata.customerAgentId" @change="dailibut">
              <el-option
                v-for="(item, index) in qudaoList"
                :label="item.agentName"
                :value="item.customerAgentId"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle redtitle">来源单据:</div>
          <div class="right_inpu">
            <el-select v-model="arraydata.financeDocumentstatusId">
              <el-option
                v-for="(item, index) in danjuList"
                :label="item.documentStatusName"
                :value="item.financeDocumentstatusId"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">来源单号:</div>
          <div class="right_inpu">
            <el-input
              v-model="arraydata.documentNumber"
              class="queryInput"
            ></el-input>
          </div>
        </div>

        <div class="queryItem1">
          <div class="queryLabel FontStyle">加收项目:</div>
          <div class="right_inpu">
            <el-select v-model="arraydata.itemServiceId" class="queryInput">
              <el-option
                v-for="(item, index) in jiashouList"
                :label="item.title"
                :value="item.itemServiceId"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">金额:</div>
          <div class="right_inpu">
            <el-input
              v-model="arraydata.settlementAmount"
              class="queryInput"
            ></el-input>
          </div>
        </div>

        <div class="queryItem1">
          <div class="queryLabel FontStyle">代理加收备注:</div>
          <div class="right_inpu">
            <el-input v-model="arraydata.comment" class="queryInput"></el-input>
          </div>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { Api } from "@/assets/js/api";
import tool from "@/assets/js/tool";
export default {
  inject: ["reload"],
  data() {
    return {
      danjuList: [], //单据列表
      jiashouList: [], //加收列表
      qudaoList: [], //渠道列表
      activeName: "second",
      input: "",
      formdata: [
        {
          aaa: "选项1",
        },
      ],
      arraydata: {
        customerAgentNumber: "", //渠道代理编号
        customerAgentId: "", //渠道代理ID
        documentNumber: "", //来源单号
        financeDocumentstatusId: "", //冲销单据ID
        financeDocumentstatusName: "", //冲销单据名称
        itemServiceId: "", //费项ID
        serviceName: "", //名称
        settlementAmount: "", //结算金额
        storageId: "", //仓库ID
        comment: "", //备注
      },
      pageType: "add",
      nid: "",
      Disable: true,
    };
  },

  mounted() {
    this.pageType = this.$route.query.pageType;
    this.nid = this.$route.query.nid;
    if (this.nid) {
      this.agentAdditionalDetail();
    }
    this.financeDocumentstatusChooseList();
    this.getCategoryServiceList();
    this.channelAgentChooseList();
  },
  methods: {
    dailibut(e) {
      for (var i = 0; i < this.qudaoList.length; i++) {
        if (this.qudaoList[i].customerAgentId == e) {
          this.arraydata.customerAgentNumber =
            this.qudaoList[i].customerAgentNumber;
        }
      }
    },
    //代理加收详情
    agentAdditionalDetail() {
      Api.agentAdditionalDetail({ otherFinanceId: this.nid }).then((res) => {
        console.log(res.data.result);
        this.arraydata = res.data.result;
      });
    },
    //渠道代理列表
    channelAgentChooseList() {
      Api.channelAgentChooseList().then((res) => {
        //console.log(res.data.result)
        this.qudaoList = res.data.result;
      });
    },
    //费项列表不分页
    getCategoryServiceList() {
      let parm = {
        directionStatus: "fu:direction_status",
        serviceStatus: "jia_shou:service_status",
      };
      Api.getCategoryServiceList(parm).then((res) => {
        //////console.log(res.data.result)
        this.jiashouList = res.data.result;
      });
    },
    //单据选择器
    financeDocumentstatusChooseList() {
      Api.financeDocumentstatusChooseList().then((res) => {
        this.danjuList = res.data.result;
      });
    },
    //取消
    cancelbut() {
      this.arraydata = {
        customerAgentNumber: "", //渠道代理编号
        customerAgentId: "", //渠道代理ID
        documentNumber: "", //来源单号
        financeDocumentstatusId: "", //冲销单据ID
        financeDocumentstatusName: "", //冲销单据名称
        itemServiceId: "", //费项ID
        serviceName: "", //名称
        settlementAmount: "", //结算金额
        storageId: "", //仓库ID
        comment: "", //备注
      };
    },

    //提交
    SubmitBut() {
      let arraydata = this.arraydata;
      if (this.pageType == "add") {
        let sign = tool.getSign(arraydata);
        arraydata.sign = sign;
        Api.addAgentAdditional(arraydata).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            tool.closePage();
            this.reload();
          }
        });
      }

      if (this.pageType == "modify") {
        delete arraydata.otherFinanceNumber;
        delete arraydata.storageName;
        let sign = tool.getSign(arraydata);
        arraydata.sign = sign;
        Api.updateAgentAdditional(arraydata).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            tool.closePage();
            this.reload();
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.headerbut {
  display: flex;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.panel {
  width: 100%;
  margin: 20px auto;
  padding: 100px 20px;
  box-sizing: border-box;
  border: 1px solid slategrey;
}

.box2 {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px #e0e0e0;
  padding: 20px;
  width: 99.5%;
  margin: 0 auto;
  display: flex;
  margin: 10px auto;
  box-sizing: border-box;
  background-color: #fff;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;
  .remarksbox {
    color: #606266;
    width: 50%;
    display: flex;
    padding: 5px 1%;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    .remarkstitle {
      width: 7%;
      line-height: 30px;
    }
    .remarksinp {
      width: 50%;
    }
  }
}
.queryItem1:hover {
  border: 1px solid #f0a261;
  box-shadow: 0px 0px 5px #f0a261;
}
//能输入样式
.queryItem1 {
  min-width: 455px;
  width: 30%;
  margin: 3px 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;

  align-items: center;
  font-size: 14px;
  .FontStyle {
    color: #f00;
    cursor: pointer;
  }
  .queryItem1:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .queryLabel {
    width: 100px;
    min-width: 100px;
    color: #606266;
    text-align: right;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .right_inpu_post {
    position: relative;
    .niming {
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 99;
    }
  }
  .right_inpu {
    width: 100%;
  }
  .right_inpu ::v-deep {
    .el-input__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 100%;
    }
  }
  .right_inpu ::v-deep {
    .el-textarea__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 375px;
    }
  }
}
</style>